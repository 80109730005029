@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

.ant-btn-primary {
  background-color: #1e3772;
}

.ant-form-item {
  margin-bottom: 0.5vw;
}

.ant-input-disabled {
  color: black !important
}

/* ::placeholder {
  color: black transparent !important;
} */

.ant-cascader-menus > .ant-cascader-menu:nth-of-type(1) {
  height: 550px; /* Replace xxx with your desired height value, e.g., 300px */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.ant-cascader-menus > .ant-cascader-menu:nth-of-type(2) {
  height: 550px; /* Replace xxx with your desired height value, e.g., 300px */
display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

/* Beyaz ve siyah arka plan rengini sırasıyla uygulamak için CSS */
.ant-cascader-menu-item:nth-child(even) div:nth-child(1) {
  background-color: #ffffff;
}

.ant-cascader-menu-item:nth-child(odd) div:nth-child(1) {
  background-color:  #f2f2f2
}